import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import AltHeader from "../components/AltHeader"
import Textures from "../components/Textures"
import CaseStudyLinks from "../components/CaseStudyLinks"
import { useSpring, animated, config } from "react-spring"

export default function CaseStudy(props) {
  const pages = [
    {
      url: "/work",
      name: "Work",
    },
    {
      url: "/about",
      name: "About",
    },
    {
      url: "/",
      name: "Home",
    },
  ]

  const currentCase = props.data.contentfulCaseStudies
  const [navOpen, setOpenState] = useState(false)
  const [isMobile, setIsMobile] = useState(null)

  const screenCheck = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    screenCheck()
    window.addEventListener("resize", () => {
      screenCheck()
    })
  })

  useEffect(() => {
    if (!isMobile && !navOpen) {
      setOpenState(true)
    }
  })

  const buttonState = useSpring({
    to: [
      {
        transform: !navOpen ? "rotate(0deg)" : "rotate(-90deg)",
      },
    ],
    config: {
      mass: 1,
      tension: 210,
      friction: 20,
      precision: 0.05,
      clamp: true,
    },
  })
  const easeInImgs = useSpring({
    to: [
      {
        transform: "translateY(0px)",
        opacity: 1,
      },
    ],
    from: { transform: "translateY(50px)", opacity: 0 },
    config: config.gentle,
  })

  return (
    <main>
      <SEO title="Case Studies" />
      <Textures />
      <main className="min-h-screen w-screen bg-primary">
        <AltHeader pages={pages} />
        <div className="lg:flex lg:px-16 lg:max-h-screen">
          <section className="flex flex-col md:items-center">
            <div
              className="relative p-6 lg:mt-10 md:overflow-y-scroll"
              css={css`
                @media screen and (min-width: 768px) {
                  width: 768px;
                  -ms-overflow-style: none; /* IE and Edge */
                  scrollbar-width: none; /* Firefox */
                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
                @media screen and (min-width: 1024px) {
                  width: max-content;
                }
              `}
            >
              <ul className={`w-full relative`}>
                {props.data.allContentfulCaseStudies.nodes.map((c, i) => {
                  const title = c.title
                  const excerpt = c.projectType
                  const slug = c.slug

                  // if (title === currentCase.title) return

                  return (
                    <li
                      key={i}
                      className={`${
                        isMobile && title !== currentCase.title && navOpen
                          ? "hidden"
                          : "block"
                      }`}
                      css={css`
                        @media screen and (min-width: 1024px) {
                          opacity: ${title !== currentCase.title ? "0.6" : "1"};
                        }
                      `}
                    >
                      {title === currentCase.title ? (
                        <div className="w-full flex items-center justify-between lg:w-auto">
                          <CaseStudyLinks
                            content={{
                              title: currentCase.title,
                              excerpt: currentCase.projectType,
                              slug: currentCase.slug,
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => setOpenState(!navOpen)}
                            className="text-3xl border border-black rounded-full w-8 h-8 flex items-center justify-center top-0 right-0 absolute z-1"
                            css={css`
                              @media screen and (min-width: 1024px) {
                                display: none;
                              }
                            `}
                          >
                            <div
                              className="absolute w-3 bg-black"
                              style={{ height: "2px" }}
                            ></div>
                            <animated.div
                              className="absolute w-3 bg-black"
                              style={buttonState}
                              css={css`
                                height: 2px;
                                transform: rotate(-90deg);
                              `}
                            ></animated.div>
                          </button>
                        </div>
                      ) : (
                        <CaseStudyLinks
                          content={{
                            title: title,
                            excerpt: excerpt,
                            slug: slug,
                          }}
                        />
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
          <animated.section
            style={easeInImgs}
            className="w-full relative p-6 pt-0 lg:pt-48 lg:overflow-scroll"
            css={css`
              &::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            {props.data.contentfulCaseStudies.caseImages.map((c, i) => {
              return (
                <Img
                  key={i}
                  fluid={c.fluid}
                  className="w-full mb-4"
                  imgStyle={{ objectFit: "contain" }}
                />
              )
            })}
          </animated.section>
        </div>
      </main>
    </main>
  )
}

export const query = graphql`
  query($title: String!) {
    contentfulCaseStudies(title: { eq: $title }) {
      title
      slug
      projectType
      caseImages {
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulCaseStudies(sort: { fields: publishedDate, order: DESC }) {
      nodes {
        title
        slug
        projectType
      }
    }
  }
`
